import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 3rem auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 12px auto;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Avatar = styled.img`
  flex: 0 0 96px;
  width: 96px;
  height: 96px;
  margin: 0;
`;

const Description = styled.div`
  flex: 1;
  margin-left: 18px;
  padding: 12px;
`;

const Username = styled.h2`
  margin: 0 0 12px 0;
  padding: 0;
`;

const Excerpt = styled.p`
  margin: 0;
`;

const User = props => (
  <UserWrapper>
    <Avatar src={props.avatar} alt="" />
    <Description>
      <Username>{props.username}</Username>
      <Excerpt>{props.excerpt}</Excerpt>
    </Description>
  </UserWrapper>
);

const Container100vh = styled.div`
  height: 100vh;
  background: #00aff0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h1`
  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  color: #ffffff;
`;

const FirstScreen = () => {
  return (
    <Container100vh>
      <Title>
        Как это сказать <br />
        по английски?..
      </Title>
      <div>
        Говорите по-английски не задумываясь с онлайн-школой английского языка
        КЭСПА
      </div>
    </Container100vh>
  );
};

const PromoPage = () => {
  return (
    <React.Fragment>
      <FirstScreen />
      <Container>
        <h1>About Styled Components</h1>
        <p>Styled Components is cool</p>
        <User
          username="Jane Doe"
          avatar="https://s3.amazonaws.com/uifaces/faces/twitter/adellecharles/128.jpg"
          excerpt="I'm Jane Doe. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
        />
        <User
          username="Bob Smith"
          avatar="https://s3.amazonaws.com/uifaces/faces/twitter/vladarbatov/128.jpg"
          excerpt="I'm Bob smith, a vertically aligned type of guy. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
        />
      </Container>
    </React.Fragment>
  );
};

export default PromoPage;
